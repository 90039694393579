<template>
  <div>
    <b-row v-if="$can('filter_logs_office', 'LoggableItemHistory')">
      <b-col
        lg="6"
        class="my-1"
      >
        <b-form-group
          label="Filter by Office"
          label-for="office-select"
        >
          <v-select
            v-model="officeFilter"
            :options="officeOptions"
            :clearable="false"
            name="office-select"
          />
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        class="my-1"
      >
        <b-form-group
          label="Filter by User"
          label-for="user-select"
        >
          <v-select
            v-model="userFilter"
            :options="userOptions"
            :clearable="false"
            name="user-select"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="showSearch"
      align-h="end"
    >
      <b-col
        class="mt-2"
        md="auto"
      >
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-alert
      :show="loggableItemHistories.length === 0 && activeUser.currentRole.name !== 'admin'"
      variant="warning"
      class="mt-2 mb-50"
    >
      <div class="alert-body">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="$t('log_entries.no_data')" />
      </div>
    </b-alert>
    <b-table
      :fields="fields"
      :items="filteredLogs"
      responsive
      striped
      bordered
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="mt-2"
      @filtered="onFiltered"
    >
      <template #cell(log_type)="data">
        {{ data.item.loggable_item.name }}
      </template>
      <template #cell(submitted_at)="data">
        {{ data.value | moment('LLL') }}
      </template>
      <template #cell(updated_at)="data">
        {{ data.value | moment('LLL') }}
      </template>
      <template #cell(office_name)="data">
        {{ data.item.user.office.name }}
      </template>
      <template #cell(user_name)="data">
        {{ data.item.user.name }} ({{ data.item.user.email }})
      </template>
      <template #cell(user_email)="data">
        {{ data.item.user.email || 'N/A' }}
      </template>
      <template #cell(actions)="data">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="m-1"
          :disabled="!data.item.can_edit || activeUser.currentRole.name === 'admin'"
          :to="{ name: 'log-entries-edit', params: { loggableItemHistoryId: data.item.id } }"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('buttons.edit') }}</span>
        </b-button>
        <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="m-1"
          :to="{ name: 'log-entries-view', params: { loggableItemHistoryId: data.item.id } }"
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('buttons.view') }}</span>
        </b-button> -->
      </template>
      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>
    <p
      v-if="filteredLogs.length == 0"
      class="text-center"
    >
      {{ $t('no_data') }}
    </p>
    <b-col
      cols="12"
    >
      <b-pagination
        v-show="filteredLogs.length > 0"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </div>
</template>

<script>
import {
  BAlert,
  BCol,
  BRow,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    BAlert,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BTable,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Log Name', sortable: true },
        { key: 'log_type', label: 'Log Type', sortable: true },
        { key: 'submitted_at', label: 'Submitted Date', sortable: true },
        { key: 'updated_at', label: 'Last Updated', sortable: true },
        { key: 'office_name', label: 'Office Name', sortable: true },
        { key: 'user_name', label: 'User', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      perPage: 5,
      currentPage: 1,
      sortBy: 'updated_at',
      sortDesc: true,
      filter: null,
      officeFilter: { label: 'All', value: 'all' },
      userFilter: { label: 'All', value: 'all' },
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('loggableItems', ['loggableItemHistories']),
    ...mapGetters('office', ['offices']),
    officeOptions() {
      const officeOptions = [{
        label: 'All',
        value: 'all',
      }]
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.offices.length; i++) {
        const office = {}
        office.label = this.offices[i].name
        office.value = this.offices[i].id
        officeOptions.push(office)
      }
      return officeOptions
    },
    users() {
      const users = new Set()
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.loggableItemHistories.length; i++) {
        users.add(JSON.stringify(this.loggableItemHistories[i].user))
      }
      return [...users].map(item => JSON.parse(item))
    },
    userOptions() {
      const userOptions = [{
        label: 'All',
        value: 'all',
      }]
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.users.length; i++) {
        const user = {}
        user.label = `${this.users[i].first_name} ${this.users[i].last_name} <${this.users[i].email}>`
        user.value = this.users[i].id
        userOptions.push(user)
      }
      return userOptions
    },
    filteredLogs() {
      try {
        if (this.officeFilter) {
          if (this.officeFilter.value === 'all' && this.userFilter.value === 'all') {
            return this.loggableItemHistories
          }
          if (this.officeFilter.value === 'all' && this.userFilter.value !== 'all') {
            return this.loggableItemHistories.filter(lih => lih.user_id === this.userFilter.value)
          }
          if (this.officeFilter.value !== 'all' && this.userFilter.value === 'all') {
            this.loggableItemHistories.filter(lih => lih.user.office_id === this.officeFilter.value)
          }
          return this.loggableItemHistories.filter(lih => lih.user.office_id === this.officeFilter.value && lih.user_id === this.userFilter.value)
        }
        return this.loggableItemHistories
      } catch (e) {
        console.warn('Could not apply filter')
        return this.loggableItemHistories
      }
    },
    totalRows: {
      get() {
        return this.filteredLogs.length
      },
      set(val) {
        return val
      },
    },
  },
  async created() {
    try {
      if (this.activeUser.currentRole.name === 'admin') {
        await this.getLoggableItemHistories()
        await this.getOffices()
      } else {
        await this.getLoggableItemHistories(this.activeUser)
      }
    } catch (e) {
      console.debug(`Error in created() of LogEntriesTable.vue: ${e.message}`)
    }
  },
  methods: {
    ...mapActions('loggableItems', ['getLoggableItemHistories']),
    ...mapActions('office', ['getOffices']),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
