<template>
  <b-row class="match-height">
    <b-col
      cols="12"
    >
      <b-card>
        <b-card-body>
          <b-row v-if="activeUser.currentRole.name !== 'admin'">
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :to="{ name: 'log-entries-new'}"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('buttons.new_log_entry') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <log-entries-table />
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import {
  BCard, BCardBody, BCol, BRow, BButton, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import LogEntriesTable from '@/views/components/tables/LogEntriesTable.vue'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardBody,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    LogEntriesTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
  },
  methods: {
  },
  metaInfo() {
    return { title: this.$t('page_titles.log_entries'), titleTemplate: 'DenTyme - %s' }
  },
}
</script>
